<template>
  <div>
    <div v-if="stillLoading">
      <vue-simple-spinner size="large" message="Loading..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="m-subheader">
        <div class="d-flex align-items-center">
          <div class="mr-auto">
            <h3 class="m-subheader__title m-subheader__title--separator">
              List {{ $route.meta.label }}
              <b style="color: blue">{{ modeForm }}</b>
            </h3>
            <p class="m-nav m-nav--inline"></p>
            <!--ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <a tabindex="0" href="#" class="" role="button" data-toggle="m-popover" data-trigger="hover" data-content="And here's some amazing content. It's very engaging. Right?">
                                <i class="m-nav__link-icon flaticon-questions-circular-button"></i>
                            </a>
                        </li>
                    </ul-->
          </div>
          <div class="d-flex align-items-right">
            <button v-if="this.modeForm == 'Verify'" @click="openDisplay" class="btn m-btn--pill m-btn--air btn-success" style="margin: 18px; width: 85%">
              /display/ {{ this.$store.getters['customer/customer'].token_trx }}
            </button>
            <button v-if="this.modeForm == 'Verify'" @click="resetDisplay" class="btn m-btn--pill m-btn--air btn-success" style="margin: 18px; width: 85%">Reset Display</button>
            <!--button v-else @click="addData" class="btn m-btn--pill m-btn--air btn-success" style="margin: 18px; width: 85%;">
                      + {{$route.meta.label}}
                  </button-->
          </div>
        </div>
      </div>
      <!-- END: Subheader -->
      <div class="m-content">
        <div class="m-portlet m-portlet--mobile">
          <div class="m-portlet__body">
            <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
              <div class="row align-items-left">
                <div class="col-xl-12 order-2 order-xl-1">
                  <div class="form-group m-form__group row align-items-center">
                    <div class="col-md-4" v-for="item in filters" v-bind:key="item.id">
                      <div v-if="item.field == 'status'" class="m-input-icon m-input-icon--left">
                        <select v-model="options[item.field]" @change="onSearch" class="form-control m-input" :placeholder="item.label">
                          <option value="" selected="selected">Status</option>
                          <option value="in" selected="selected">In</option>
                          <option value="out" selected="selected">Out</option>
                        </select>
                      </div>
                      <div v-else-if="item.field != 'btn'" class="m-input-icon m-input-icon--left">
                        <input @change="onSearch" v-model="options[item.field]" class="form-control m-input" :placeholder="item.label" />
                        <span class="m-input-icon__icon m-input-icon__icon--left">
                          <span>
                            <i class="la la-search"></i>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <vue-good-table
              mode="remote"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPage: perPage,
                position: 'bottom',
                perPageDropdown: [5, 10],
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All'
              }"
              :totalRows="totalRecords"
              :rows="data"
              :columns="columns"
              :sort-options="{
                enabled: false
              }"
              @on-page-change="onPageChange"
              @on-per-page-change="onPageChange"
              :row-style-class="rowStyleClassFn"
            >
              <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'btn'">
                  <!--button
                            class="btn btn-primary "
                            type="button"
                            @click="preview(props.row.id)"
                          >
                            Preview
                          </button-->

                  <button class="btn btn-primary" type="button" @click="editMaster(props.row.id)">Edit</button>
                  <button v-if="($store.getters['customer/customer'].role_id = 1)" class="btn btn-danger" type="button" @click="deleteMaster(props.row.id)">Delete</button>
                </div>
                <span v-else-if="props.column.field == 'url'">
                  <img
                    :src="
                      props.row.url +
                      (($route.meta.testingName == 'h') | ($route.meta.testingName == 'i') | ($route.meta.testingName == 'j') | ($route.meta.testingName == 'k') | ($route.meta.testingName == 'z')
                        ? '?onlyAnnotation=0'
                        : '')
                    "
                    class="img-fluid"
                    alt="Image"
                  />
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Absensi',
  mounted: function () {
    this.initData()
  },
  data: function () {
    return {
      searchTerm: null,
      options: {
        limit: 11,
        page: 1,
        sort: '',
        nama: ''
      },
      //columns: [],
      perPage: 10,
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: ''
    }
  },
  methods: {
    initData() {
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      let paramsTemp = queryString.stringify({
        ...{
          name: null,
          sort: ''
        },
        ...this.options
      })
      this.$store
        .dispatch('absensi/GET_LIST_ABSENSI', {
          masterType: this.$route.meta.name,
          params: paramsTemp
        })
        .then(() => {
          this.counterProgress = 100
          this.showProgress = false
          this.stillLoading = false
        })
    },
    unlock() {
      if (this.unlockPassword == this.$store.getters['customer/customer'].password) {
        this.locked = false
      } else {
        alert('Password infalid')
      }
    },
    preview(masterId) {
      this.$router.push('/absensi/' + masterId)
    },
    onPageChange(params) {
      this.options.limit = params.currentPerPage
      this.options.page = params.currentPage
      let paramsTemp = queryString.stringify({
        ...{
          limit: params.currentPerPage,
          page: params.currentPage,
          sort: ''
        },
        ...this.options
      })
      this.$store.dispatch('absensi/GET_LIST_ABSENSI', {
        masterType: this.$route.meta.name,
        params: paramsTemp,
        testingName: this.$route.meta.testingName
      })
    },

    onSearch(event) {
      this.options.page = 1
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })
      this.$store.dispatch('absensi/GET_LIST_ABSENSI', {
        masterType: this.$route.meta.name,
        params: paramsTemp,
        testingName: this.$route.meta.testingName
      })
    },
    editMaster(masterId) {
      this.$router.push('/absensi/edit/' + masterId)
    },
    deleteMaster(masterId) {
      let paramsTemp = queryString.stringify({
        ...{
          limit: null,
          page: null,
          nama: null,
          sort: '',
          testingName: this.$route.meta.testingName
        },
        ...this.options
      })

      this.$dialog
        .confirm('Are you sure you want to delete this?', {
          okText: 'Yes',
          cancelText: 'No',
          loader: true,
          animation: 'bounce'
        })
        .then((dialog) => {
          dialog.close()
          this.$store
            .dispatch('absensi/DELETE_ABSENSI', {
              masterType: this.$route.meta.name,
              masterId: masterId,
              testingName: this.$route.meta.testingName
            })
            .then((resp) => {
              if (resp.data.status == '200 OK') {
                this.$store.dispatch('absensi/GET_LIST_ABSENSI', {
                  masterType: this.$route.meta.name,
                  params: paramsTemp
                })
              }
            })
        })
        .catch(function () {})
    },

    addData() {
      this.$router.push('/' + this.$route.meta.name + '-Add')
    },
    openDisplay() {
      this.$router.push('/display/' + this.$store.getters['customer/customer'].token_trx)
    },
    resetDisplay() {
      this.$store.dispatch('customer/RESET_TOKEN_TRX', {}).then((resp) => {
        if (resp.data.status == '200 OK') {
          // this.$swal(resp.data.status)
        }
      })
    },
    rowStyleClassFn(row) {
      let result = 'default'
      // if (row.id % 2 == 1 ){
      //     result='green'
      // } else if (row.id == 0 ){
      //     result='red'
      // }
      return result
    }
  },
  computed: {
    data: function () {
      return this.$store.getters['absensi/list_absensi'] ? this.$store.getters['absensi/list_absensi'] : []
    },
    totalRecords: function () {
      return this.$store.getters['absensi/paginate'].total ? this.$store.getters['absensi/paginate'].total : 0
    },
    columns: function () {
      return [
        { field: 'id', label: 'Id' },
        { field: 'waktu', label: 'Waktu' },
        { field: 'email', label: 'Email' },
        { field: 'nama', label: 'Nama' },
        { field: 'kelas', label: 'Kelas' },
        { field: 'telah', label: 'Keterangan' },
        { field: 'status', label: 'Status' },
        { field: 'ke', label: 'Ke' },
        { field: 'created_date', label: 'created_date' },
        { field: 'btn', label: 'Action', tdClass: 'custom-action', thClass: 'custom-action-head' }
      ]
    },
    filters: function () {
      return [
        { field: 'email', label: 'Email' },
        { field: 'nama', label: 'Nama' },
        { field: 'kelas', label: 'Kelas' },
        { field: 'status', label: 'Status' }
      ]
    },
    level_id: function () {
      return this.$store.getters['customer/customer'] ? this.$store.getters['customer/customer'].level_id : 0
    }
  },
  created: function () {
    this.initData()
  },
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}
</script>
<style scoped>
.red {
  background: linear-gradient(#f4f5f8, #e2133f);
}
.green {
  background: linear-gradient(#f4f5f8, #57c137);
}
.default {
  background: linear-gradient(#f4f5f8, #f1f3f6);
}
</style>
